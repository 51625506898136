<template>
	<div>
		<ul class="list-group">
			<li class="list-group-item">
				Arbetsgivaravgiften (AgA):
				<span class="font-weight-bolder"> 0,239045 </span>
			</li>
			<li class="list-group-item">
				Semesterersättning (SEr):
				<span class="font-weight-bolder"> 0,107143 </span>
			</li>
			<li class="list-group-item">
				Tax in gross sum (TAX):
				<span class="font-weight-bolder"> 0,193675 </span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SalaryTaxes',
};
</script>

<style scoped></style>
