<template>
	<div>
		<SalaryContainer />
	</div>
</template>

<script>
import SalaryContainer from '@/components/SalaryContainer';
export default {
	name: 'App',
	components: {
		SalaryContainer,
	},
};
</script>

<style></style>
